<template>
  <div class="header">
    <!-- web端顶部 -->
    <div class="firstHead">
      <div class="left">
        <img src="../../assets/web/header/logo3.png" alt="">
        <span></span>
        <p class="p24">0755-25857209</p>
      </div>
      <div class="right">
        <el-input placeholder="请输入搜索内容" v-model="searchValue">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="searchArticle"></i>
        </el-input>
        <!-- @mouseenter="iconShow = true;" -->
        <img @mouseover="iconShow = true;" v-if="!iconShow" src="../../assets/web/header/yuyan-shi@2x.png" alt="">
        <img @mouseleave="iconShow = false;" @click="openChoose" v-else src="../../assets/web/header/yuyan-shi备份@2x.png"
          alt="">
        <ul v-if="showChoose">
          <li @click="changeLanguage('/home')">简体</li>
          <li @click="changeLanguage('/tcc/index')">繁体</li>
          <li @click="changeLanguage('/english/index')">English</li>
        </ul>
      </div>

    </div>
    <div class="secondHead">
      <div class="webMain">
        <ul>
          <!-- {{ activePath }} -->
          <li v-for="item in menuList" :key="item.id" @click="checkPath(item)">
            <span :class="activePath === item.path ? 'span17 active' : 'span17'">{{ item.title }} </span>

          </li>
        </ul>
      </div>
      <div class="phoneMenu">
        <div></div>
        <div class="left"><span>{{ activeName }}</span></div>
        <div class="right">
          <i class="el-icon-s-fold" @click="openDrawer"></i>
        </div>
      </div>
    </div>
    <div class="phoneDrawer">
      <el-drawer :with-header="false" :visible.sync="drawer">
        <!-- <span>我来啦!</span> -->
        <ul>
          <li @click="checkPath(item.url)" v-for="item in menuList" :key="item.id"
            :class="activePath === item.path ? 'active' : ''">
            <span>{{ item.title }} </span>
          </li>
        </ul>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { detailMenuAPI } from '@/api/settings/TCCMenu'
// import { menuListAPI } from '@/api/settings/menu'
import { articleTypeListAPI } from '@/api/article/TCCType'
import { attachmentListAPI } from '@/api/attachment/attachment'
export default {
  data() {
    return {
      showChoose: false,
      iconShow: false,
      drawer: false,
      menuList:  [
        { id: 1, title: '首頁', path: '/tcc/index', menuId: 12 },
        { id: 2, title: '企業服務(境內)', path: '/tcc/list/1', categoryId: 1124, menuId: 13 },
        { id: 3, title: '海外服務', path: '/tcc/list/2', categoryId: 1125, menuId: 14 },
        { id: 4, title: '稅務法律', path: '/tcc/list/3', categoryId: 1126, menuId: 35 },
        { id: 5, title: '數位化與互聯網', path: '/tcc/list/4', categoryId: 1127, menuId: 15 },
        { id: 6, title: '教育', path: '/tcc/list/5', categoryId: 1128, menuId: 16 },
        { id: 7, title: '會議展覽', path: '/tcc/list/6', categoryId: 1129, menuId: 17 },
        { id: 8, title: '成功案例', path: '/tcc/list/7', categoryId: 1130, menuId: 18 },
        { id: 9, title: '關於我們', path: '/tcc/contact', menuId: 19 },


      ],
      // textList: [
      //   {id: 1, path: ''}
      // ],
      paging: {
        pageSize: 1,
        pageNum: 1,
        total: 0
      },
      searchValue: null,
      // AttachmentList: []
    }
  },

  
  methods: {
    // 手机侧边栏
    openDrawer() {
      this.drawer = true;
    },
    // 获取附件列表
    async getAttachmentList(val) {
      // console.log(val)
      let info = { pageSize: 100000, pageNum: 1, categoryId: 18 }
      const res = await attachmentListAPI(info);
      console.log(res);
      if (res.code === 200) {
       let data =  this.$utils.toName(this.activeName)
        // this.imgList = res.data.list;
        let name = data + '.png'
        console.log(name)
        res.data.list.forEach(item => {
          if (item.title === name) {
            // console.log(item)
            this.$store.commit('changeImgPath', item.path)
          }
        })
      } 
    },
    // 获取banner区域的文字
    async getBannerDetail(val) {
      const res = await detailMenuAPI({ id: val });
      console.log(res);
      if (res.code === 200) {
        // this.bannerInfo = res.data;
        this.$store.commit('changeBannerText', res.data)
      }
    },
    // 路由切换
    // 切换路由
    checkPath(val) {
      // console.log(val)
      this.getBannerDetail(val.menuId);
      this.$store.commit('changeFirstName', val);
      this.getAttachmentList();
      this.typeList.forEach(item => {
        if (item.id === val.categoryId) {
          // sessionStorage.setItem('categoryId', (item.children[0]).id)
          this.$store.commit('changeNav', item.children);
          
        }
      })
      sessionStorage.setItem('path',val.path)
      this.$router.push(val.path);
      
      // this.getList();
      this.drawer = false;
    },
    // 获取文章分类列表
    async getTypeList() {
      let info = { pageNum: 1, pageSize: 100 };
      const res = await articleTypeListAPI(info);
      console.log(res);
      if (res.code === 200) {
        this.typeList = res.data.list;
        // console.log(JSON.stringify(this.typeList))
        sessionStorage.setItem('typeList', JSON.stringify(this.typeList))
        // this.dataList();
      }
    },
    // 点击搜索
    searchArticle() {

      if (this.searchValue !== null || this.searchValue !== '') {
        this.getBannerDetail(12);
        sessionStorage.setItem('path', '/tcc/index');
        // sessionStorage.setItem('firstName', '首页');
        // sessionStorage.setItem('secondName', '搜索');
        if (this.$route.path !== '/tcc/searchList') {

          this.$router.push({
            path: '/tcc/searchList',
            query: {
              content: this.searchValue
            }
          })
        } else {
          this.$router.push({
            path: '/tcc/searchList',
            query: {
              content: this.searchValue
            }
          })
          location.reload();
        }
      }

    },
    // 页面刷新时，找到对应的二级菜单和banner区域文字
    dataList() {
      // console.log(this.$route.path);
      let categoryId = null;
      console.log(this.menuList)
      this.menuList.forEach(item => {
        if(item.path === this.$route.path) {
          // console.log(item)
          this.getBannerDetail(item.menuId);
          categoryId = item.categoryId
        }
      })
      let typeList = JSON.parse(sessionStorage.getItem('typeList'))
      // console.log(typeList)
      typeList.forEach(item => {
        if (item.id === categoryId) {
          // console.log(item)
          this.$store.commit('changeNav', item.children)
        }
      })
    },
    // 切换语言
    changeLanguage(val) {

      this.$router.push(val)
    },
    openChoose() {
      this.showChoose = !this.showChoose;

    },
    // closeChoose() {
    //   this.showChoose = false;
    // }
  },
  created() {
    this.getTypeList();
    this.getAttachmentList();
    this.dataList();

    // this.getList();
    // 页面首次加载时获取二级菜单
    // setTimeout(() => {
    //   this.$store.dispatch('getMenuList', this.$route.path);
    //   this.$store.dispatch('getArticleType');
    // }, 500);

  },

  computed: {
    activePath() {
      if ((this.$route.path).indexOf('/tcc/detail') !== -1 || (this.$route.path).indexOf('/tcc/searchList') !== -1) {
        return sessionStorage.getItem('path')
      } else {
        return this.$route.path;
      }
    },
    activeName() {
      let url = ''
      // let setPath = sessionStorage.getItem('path');
      if ((this.$route.path).indexOf('/tcc/detail') !== -1 || (this.$route.path).indexOf('/tcc/searchList') !== -1) {
        url = sessionStorage.getItem('path')
      } else {
        url = this.$route.path
      }
      console.log(url)
      let text = null;
      console.log(this.menuList)
       this.menuList.filter(item => {
        if (item.path === url) {
          text =  item
        }
      })
      console.log(text)
      return text.title
      // console.log(this.$route.path)
    },
  },

}
</script>

<style lang="scss" scoped>
.header {

  // height: 130px;
  background-color: white;
  position: sticky;
  top: 0px;
  z-index: 3;
  // position: relative;

  // background: wheat;
  .firstHead {
    display: flex;
    // height: 65px;
    padding: 12px 40px;
    border-bottom: 1px solid #D8D8D8;
    box-sizing: border-box;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      img {
        height: 40px;
      }

      span {
        margin: 0px 10px;
        display: inline-block;
        width: 2px;
        height: 20px;
        background: #0A3A7F;
      }

      p {
        font-family: PangMenZhengDao;
        color: var(--custom-color1);
        // line-height: 41px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      position: relative;

      img {
        height: 27px;
        margin-left: 27px;
        cursor: pointer;
      }

      .el-input {
        width: 276px;
        height: 40px;
      }

      ul {
        right: -38px;
        position: absolute;
        bottom: -145px;
        background: #fff;
        list-style: none;
        margin: 0;
        padding: 0;
        border-radius: 5px;

        li {
          cursor: pointer;
          padding: 0 40px;
          line-height: 45px;
          color: var(--custom-color1);
          border-bottom: 1px solid #D8D8D8;
          font-size: 14px;
          text-align: center;
        }
      }

      // text-align: right;
    }
  }

  @keyframes fontHover {
    from {
      color: black;
      // border-bottom: 6px solid white;
    }

    to {
      color: var(--custom-color1);
      // border-bottom: 6px solid var(--custom-color1);
    }
  }

  .secondHead {
    // position: sticky;
    // top: 0px;
    // z-index: 3;
    height: 65px;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);

    .webMain {
      height: 100%;

      ul {
        height: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            span {
              animation-name: fontHover;
              animation-duration: 0.5s;
              animation-fill-mode: both;
            }
          }


          span {
            font-weight: 600;
            display: inline-block;
            padding-bottom: 5px;
            box-sizing: border-box;
          }

          .active {
            color: var(--custom-color1);
            border-bottom: 6px solid var(--custom-color1);
            // height: 6px;
            // background: ;
          }
        }
      }
    }

    .phoneMenu {
      display: none;
    }
  }
}

// @media screen and (min-width:900px) and (max-width:1366px) {
//   .header {
//     .secondHead {
//       .webMain {
//         width: 850px;
//       }
//     }
//     .firstHead{

//     }
//   }
// }

@media screen and (max-width:1000px) {
  .phoneDrawer {
    background: white;

    // .v-modal{
    //  z-index: 2 !important;
    // }
    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        text-align: center;
        line-height: 60px;
        cursor: pointer;

        span {
          font-size: 16px;
        }
      }

      .active {
        border-bottom: 5px solid var(--custom-color1);

        span {
          color: var(--custom-color1);
        }
      }
    }
  }

  .header {
    .firstHead {

      // display: block;
      // padding: 12px;
      .left {
        img {
          height: 24px;
          width: 140px;
        }

        p,
        span {
          font-size: 18px;
        }

        margin-bottom: 10px;

      }
    }

    .secondHead {

      .webMain {
        display: none;
      }

      .phoneMenu {
        padding: 0 30px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
          i {
            font-size: 30px;
          }
        }

        .left {
          span {
            font-size: 20px;
            font-weight: 600;
            color: var(--custom-color1);
          }
        }
      }
    }
  }
}

// @media screen and (max-width:683px){
//   .header{
//     .firstHead{
//       display: block;
//     }
//   }
// }
</style>