<template>
    <div class="webHome">
        <!-- 首页 -->
        <Banner></Banner>
        <!-- — 骏领国际热门服务 — -->
        <div class="PopularServices mainItem">
            <div class="webMain">
                <p class="p30 title">— International popular services —</p>
                <div class="servicesContent">
                    <!-- <table>
                        <tr>
                            <td></td>
                        </tr>
                    </table> -->
                    <div class="item" v-for="item in ServicesList" :key="item.id" @click="toPath(item)">
                        <div class="centerContent centerContent1">
                            <img :src="require(`../../assets/web/index/${item.icon}`)" alt="">
                            <p class="span17">{{ item.name }}</p>
                        </div>
                        <div class="centerContent centerContent2">
                            <img :src="require(`../../assets/web/index/${item.icon}`)" alt="">
                            <p class="span17">{{ item.name }}</p>
                            <div class="more">
                                <span>Learn more</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- —  值得信赖的企业管家  — -->
        <div class="entrepreneur mainItem">
            <div class="webMain">
                <p class="p30 title">— Trusted Business Manager —</p>
                <div class="entrepreneurContent">
                    <div class="item" v-for="(item, index) in entrepreneurList" :key="item.id">
                        <img :src="$store.state.requestAPI + item.thumbnail" alt="">
                        <div class="styleBox">
                            <p class="number p32">0{{ index + 1 }}</p>
                            <p class="qTitle p22">{{ item.title }}</p>
                            <p class="qContent p16">{{ item.summary }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="upgrade mainItem">
            <div class="webMain">
                <p class="p30 title">— Junling International has helped thousands of enterprises upgrade in all aspects —</p>
                <div class="upgradeContent">
                    <template>
                        <el-carousel :interval="10000" arrow="always" indicator-position="outside" @change="changeCarousel">
                            <!-- <el-carousel-item v-for="item in bannerList" :key="item.id">
                                <div class="container">
                                    <div class="item" v-for="i in item.list" :key="i.id">
                                        <img :src="require(`../../assets/web/index/${i.imgPath}`)" alt="">
                                        <p class="text p22">{{ i.title }}</p>
                                        <p class="content p16">{{ i.content }}</p>
                                        <div class="bottomStyle"></div>
                                    </div>
                                </div>
                            </el-carousel-item> -->
                            <el-carousel-item v-for="item in paging.total" :key="item.id">
                                <div class="container">
                                    <div class="item" v-for="i in bannerList" :key="i.id" @click="toDetail(i.id)">
                                        <!-- <img :src="require(`../../assets/web/index/${i.imgPath}`)" alt=""> -->
                                        <img v-if="i.thumbnail" :src="$store.state.requestAPI + i.thumbnail" alt="">
                                        <img v-else src="../../assets/web/index/编组 33@2x.png" alt="">
                                        <p class="text p22">{{ i.title }}</p>
                                        <p class="content p16">{{ i.summary }}</p>
                                        <div class="bottomStyle"></div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </template>
                </div>
            </div>
        </div>
        <div class="encourage mainItem">
            <div class="webMain">
                <p class="p30 title">— The recognition of every enterprise is an encouragement to us —</p>
                <div class="encourageContent">
                    <div class="item" v-for="item in encourageList" :key="item.id">
                        <p class="number">
                            {{ item.number }}
                            <span>{{ item.character }}</span>
                        </p>
                        <div class="solid"></div>
                        <p class="name">{{ item.name }}</p>
                        <img :src="require(`../../assets/web/index/${item.icon}`)" alt="">
                        <div class="bottomStyle"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banner from '@/components/EnglishWeb/Banner.vue'
import { articleListAPI } from '@/api/article/EnglishArticle'
import { menuListAPI, detailMenuAPI } from '@/api/settings/EnglishMenu'
import { attachmentListAPI } from '@/api/attachment/attachment'
import { server } from '@/utils/server'
export default {
    components: { Banner },
    data() {
        return {
            ServicesList: [
                { id: 1, name: 'Oversea company', icon: 'icon1.png', path: '/english/list/2', categoryId: 1125, menuId: 14, menuName: 'Overseas services' },
                { id: 2, name: 'Opening an account with an overseas bank', icon: 'icon2.png', path: '/english/list/2', categoryId: 1125, menuId: 14, menuName: 'Overseas services' },

                { id: 3, name: 'Government investment and business services', icon: 'icon3.png', path: '/english/list/6', categoryId: 1129, menuId: 17, menuName: 'Conference Exhibition' },

                { id: 4, name: 'Enterprise Management Consulting', icon: 'icon4.png', path: '/english/list/1', categoryId: 1124, menuId: 13 , menuName: 'Enterprise Services (Domestic)'},
                { id: 5, name: 'Dataization and the Internet', icon: 'icon5.png', path: '/english/list/4', categoryId: 1127, menuId: 15, menuName: 'Digitalization & the Internet' },
                { id: 6, name: 'Tax services', icon: 'icon6.png', path: '/english/list/3', categoryId: 1126, menuId: 35 , menuName: 'tax law'},
                { id: 7, name: 'Legal Advisor', icon: 'icon7.png', path: '/english/list/3', categoryId: 1126, menuId: 35, menuName: 'tax law' },
                { id: 8, name: 'Intellectual Property Services', icon: 'icon8.png', path: '/english/list/1', categoryId: 1124, menuId: 13 , menuName: 'Enterprise Services (Domestic)'},
            ],
            entrepreneurList: [
            ],
            encourageList: [
                { id: 1, name: 'Service experience', number: 10, character: '+', icon: 'fuwu (3).png' },
                { id: 2, name: 'Service enterprises', number: 20000, character: '+', icon: 'fuwu (2).png' },
                { id: 3, name: 'Service Cases', number: 2147, character: '+', icon: 'fuwu (1).png' },
                { id: 4, name: 'Service guarantee', number: 100, character: '%', icon: 'fuwu (4).png' },
            ],
            bannerList: [],
            paging: {
                pageNum: 1,
                pageSize: 3,
                total: 0
            }
        }
    },
    methods: {
        // 获取文章列表
        async getArticleList() {
            // 值得信赖的企业管家
            let info = {
                pageNum: 1,
                pageSize: 4,
                categoryId: 1183,
                status: 'normal'
            }
            // 骏领国际已助力数千家企业全方面升级 
            let info2 = {
                pageNum: this.paging.pageNum,
                pageSize: this.paging.pageSize,
                categoryId: 1186,
                status: 'normal'
            }
            const res = await articleListAPI(info);
            // console.log(res);

            if (res.code === 200) {
                if (res.data.list.length > 0) {
                    this.entrepreneurList = res.data.list;
                }
                // this.paging.total = res.data.total;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
            let info3 = {
                pageNum: 1,
                pageSize: 3,
                categoryId: 1186,
                status: 'normal'
            }
            let firstList = [];
            const res3 = await articleListAPI(info3);
            console.log(res3);
            if (res3.code === 200) {
                firstList = res3.data.list
            }

            const res2 = await articleListAPI(info2);
            console.log(res2);



            if (res2.code === 200) {
                this.bannerList = res2.data.list;
                console.log(res2.data.total)
                this.paging.total = Math.ceil(((res2.data.total) / this.paging.pageSize));
                if (this.paging.pageSize === 3) {
                    if (this.bannerList.length === 1) {
                        this.bannerList = [...this.bannerList, firstList[0], firstList[1]]
                    } else if (this.bannerList.length === 2) {
                        this.bannerList = [...this.bannerList, firstList[0]]
                    }
                } else if (this.paging.pageSize === 2) {
                    if (this.bannerList.length === 1) {
                        this.bannerList = [...this.bannerList, firstList[0]]
                    }
                }

                // console.log(this.paging.pageSize)
                // console.log(this.paging.total)
            }
        },
        // 热门服务区域跳转
        toPath(val) {
            // console.log(val)
            this.getBannerDetail(val.menuId);
            this.getAttachmentList(val);
            let typeList = JSON.parse(sessionStorage.getItem('typeList'))
            // console.log(typeList)
            typeList.forEach(item => {
                if (item.id === val.categoryId) {
                    // console.log(item)
                    this.$store.commit('changeNav', item.children, item.id)
                }
            })
            this.$store.commit('changeFirstName', {title: val.menuName});
            this.$router.push(val.path)
            
        },
        // 获取附件列表
        async getAttachmentList(val) {
            // console.log(val)
            let info = { pageSize: 100000, pageNum: 1, categoryId: 18 }
            const res = await attachmentListAPI(info);
            console.log(res);
            if (res.code === 200) {
                let name = val.menuName + '.png';
                res.data.list.forEach(item => {
                    if (item.title === name) {
                        // console.log(item)
                        this.$store.commit('changeImgPath', item.path)
                    }
                })
            }
        },
        // 获取菜单列表
        async getList() {
            let pathName = null;
            let info = { pageSize: 1000, pageNum: 1 }
            const res = await menuListAPI(JSON.stringify(info));
            console.log(res);
            if (res.code === 200) {
                this.menuList = res.data.list;
                this.$store.commit('changeMenuList', res.data.list);
                // console.log(this.$store.state.menuList)
                // 提取banner部分的内容
                res.data.list.forEach(item => {
                    if (this.$route.path === item.url) {
                        pathName = item.text;
                        this.$store.commit('changeImgListText', item)
                    }
                })
                // console.log(pathName)
                this.getAttachmentList(pathName);
                // this.tableData = res.data.list;

            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
        },
        // 获取banner区域的文字
        async getBannerDetail(val) {
            const res = await detailMenuAPI({ id: val });
            console.log(res);
            if (res.code === 200) {
                // this.bannerInfo = res.data;
                this.$store.commit('changeBannerText', res.data)
            }
        },
        // 切换幻灯片
        changeCarousel() {
            if (document.body.clientWidth > 1200) {
                this.paging.pageSize = 3
            } else {
                this.paging.pageSize = 2
            }
            if (this.paging.pageNum < this.paging.total) {
                this.paging.pageNum = this.paging.pageNum + 1;
                this.getArticleList();
            } else {
                this.paging.pageNum = 1;
                this.getArticleList();
            }
        },
        // 查看详情
        toDetail(val) {
            this.$store.commit('changeFirstName', {title: 'home'});
            this.$store.commit('changeActiveName', {title: 'case'});
            this.$router.push(`/english/detail/${val}`)
        }

    },
    created() {

        // console.log(document.body.clientWidth)
        if (document.body.clientWidth > 1200) {
            this.paging.pageSize = 3
        } else {
            this.paging.pageSize = 2
        }
        this.getArticleList();
        server();
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__arrow {
    background-color: rgba(0, 0, 0, 0.4)
}

.webHome {
    @keyframes bottom {
        from {
            height: 10px;
            width: 0px;
            background-color: var(--second-color);
        }

        to {
            height: 10px;
            width: 100%;
            background-color: var(--second-color);
        }
    }

    @keyframes shadow {
        from {
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.22);
        }

        to {
            box-shadow: 10px 10px 16px 0px rgba(0, 0, 0, 0.22);
        }
    }

    .mainItem {
        padding-top: 60px;

        .title {
            text-align: center;
            font-weight: 600;
            color: var(--title-color);
            margin-bottom: 30px;
        }
    }

    .PopularServices {
        padding-bottom: 60px;

        .webMain {
            .servicesContent {
                box-sizing: border-box;
                display: flex;
                // justify-content: space-evenly;
                flex-wrap: wrap;
                justify-content: center;
                // border: 1px solid #BBBBBB;
            }

            .item {
                border: 1px solid #BBBBBB;
                box-sizing: border-box;
                // border-collapse:separate;
                // border-collapse:collapse;
                // display: inline-block;
                // float: left;
                width: 300px;
                height: 208px;
                background: #FFFFFF;
                // border-bottom: 1px solid #BBBBBB;
                display: flex;
                align-items: center;
                justify-content: center;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    border-bottom: 0px;
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    border-right: 0px;
                }

                &:hover {
                    align-items: flex-start;
                    padding-top: 14px;
                    // border: 1px solid white;
                    z-index: 2;
                    animation-name: hotService;
                    animation-duration: 0.5s;
                    animation-fill-mode: both;
                    // box-shadow: -8px -8px 12px 0px rgba(0, 0, 0, 0.2), 8px 8px 12px 0px rgba(0, 0, 0, 0.2);

                    .centerContent1 {
                        display: none;
                    }

                    .centerContent2 {
                        display: block;


                    }
                }

                .centerContent {
                    text-align: center;

                    img {
                        width: 79px;
                    }

                    p {
                        font-weight: 600;
                        color: #222222;
                    }
                }

                .centerContent2 {
                    display: none;

                    .more {
                        margin: auto;
                        margin-top: 13px;
                        width: 224px;
                        height: 40px;
                        line-height: 40px;
                        background: #FFFFFF;
                        border: 2px solid var(--second-color);
                        cursor: pointer;

                        span {
                            font-size: 16px;
                            font-weight: 600;
                            color: var(--second-color);
                        }
                    }
                }



            }

            @keyframes hotService {
                from {
                    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.2);
                }

                to {
                    box-shadow: -8px -8px 12px 0px rgba(0, 0, 0, 0.2), 8px 8px 12px 0px rgba(0, 0, 0, 0.2);
                    border: 1px solid white;
                }
            }
        }
    }

    .entrepreneur {
        // background: #BBBBBB;
        background-image: url('../../assets/web/index/编组\ 2@2x.png');
        padding: 60px 0px;

        .entrepreneurContent {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .item {
                height: 360px;
                width: 295px;
                // width: 25%;
                overflow: hidden;
                position: relative;
                box-sizing: border-box;




                img {
                    width: 100%;
                    height: 100%;
                }

                .styleBox {
                    width: 100%;
                    box-sizing: border-box;
                    position: absolute;
                    // bottom: 0px;
                    background: var(--custom-color1);
                    padding: 10px 30px;
                    padding-bottom: 30px;

                    .number {
                        font-weight: 600;
                        color: #DFECFF;
                        margin-bottom: 4px;
                    }

                    .qTitle {
                        color: var(--second-color);
                        margin-bottom: 14px;
                    }

                    .qContent {
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 25px;
                    }
                }

                &:nth-child(2),
                &:nth-child(4) {
                    .styleBox {
                        height: 100px;
                        overflow: hidden;
                        top: 0px;
                    }

                    &:hover {
                        .styleBox {
                            animation-name: example2;
                            animation-duration: 1s;
                            animation-fill-mode: both;
                        }

                    }
                }

                &:nth-child(1),
                &:nth-child(3) {
                    .styleBox {
                        min-height: 280px;
                        bottom: -180px;
                    }

                    &:hover {
                        .styleBox {
                            animation-name: example;
                            animation-duration: 1s;
                            animation-fill-mode: both;
                        }

                    }
                }
            }
        }

        @keyframes example {
            from {
                bottom: -180px;
            }

            to {
                bottom: 0px;
            }
        }

        @keyframes example2 {
            from {
                height: 100px;
            }

            to {
                height: 280px;
            }
        }
    }

    .upgradeContent {
        padding-bottom: 40px;

        ::v-deep .el-carousel__container {
            height: 420px !important;
        }

        .container {
            display: flex;
            justify-content: space-between;

            .item {
                width: 374px;
                // margin: 0px 10px;
                // height: 458px;
                background: var(--third-color);
                position: relative;
                padding-bottom: 20px;
                cursor: pointer;

                .bottomStyle {
                    position: absolute;
                    bottom: 0;
                }

                &:hover {
                    animation-name: shadow;
                    animation-duration: 0.5s;
                    animation-fill-mode: both;

                    .bottomStyle {
                        animation-name: bottom;
                        animation-duration: 0.5s;
                        animation-fill-mode: both;
                    }
                }

                img {
                    height: 210px;
                    width: 100%;
                }

                p {
                    padding: 0px 20px;
                    color: #222222;
                }

                .text {
                    padding-top: 13px;
                    font-weight: 600;
                    padding-bottom: 5px;
                    display: -webkit-box;
                    overflow: hidden;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }

                .content {
                    line-height: 25px;
                    display: -webkit-box;
                    overflow: hidden;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                }
            }
        }

        ::v-deep .el-carousel__arrow {
            height: 70px;
            width: 70px;
            font-size: 50px;
        }

        // ::v-deep .el-carousel__container {
        //     height: 458px !important;
        // }

        .el-carousel--horizontal {
            z-index: 0;
        }

        ::v-deep .el-carousel--horizontal {
            z-index: 0;
        }

        .el-carousel__item img {
            height: 100%;
        }

        ::v-deep .el-carousel__indicator--horizontal {
            padding: 15px 13px;
        }

        ::v-deep .el-carousel__indicator.is-active button {
            background-color: var(--custom-color1);
        }

        ::v-deep .el-carousel__button {
            background-color: white;
            opacity: 1;
            height: 20px;
            width: 20px;
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.34);
            border: 2px solid var(--custom-color1);
            border-radius: 50%;
        }
    }

    .encourage {
        padding: 60px 0px;
        background-image: url('../../assets/web/index/1411677832157_.pic_hd@2x.png');

        .encourageContent {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .item {
                width: 273px;
                height: 408px;
                padding-bottom: 10px;
                margin: 5px 15px;
                background-color: var(--custom-color1);
                box-sizing: border-box;
                padding-top: 70px;
                text-align: center;
                position: relative;
                margin-bottom: 10px;

                &:last-child {
                    margin-right: 0px;
                }

                &:first-child {
                    margin-left: 0px;
                }

                .bottomStyle {
                    position: absolute;
                    bottom: 0px;
                }

                p {
                    color: #FFFFFF;
                }

                .name {
                    font-size: 26px;
                    font-weight: 600;

                }

                .solid {
                    margin: 0 auto;
                    margin-top: 15px;
                    margin-bottom: 25px;

                    width: 102px;
                    height: 2px;
                    background: #629BEC;
                    opacity: 0.3;
                }

                .number {
                    font-size: 60px;
                    font-weight: bold;
                    position: relative;

                    span {
                        font-size: 26px;
                        position: absolute;
                    }

                }

                img {
                    margin-top: 20px;
                    width: 55px;
                }

                &:hover {
                    // box-shadow: 10px 10px 16px 0px rgba(4, 23, 50, 0.55);
                    // border-bottom: 10px solid var(--second-color);
                    animation-name: shadow;
                    animation-duration: 0.5s;
                    animation-fill-mode: both;

                    .bottomStyle {
                        animation-name: bottom;
                        animation-duration: 0.5s;
                        animation-fill-mode: both;
                    }
                }
            }

            @keyframes encourage {
                from {
                    // bottom: -180px;
                    box-shadow: 0px 0px 0px 0px rgba(4, 23, 50, 0.55);
                    border-bottom: 1px solid var(--second-color);
                }

                to {
                    // bottom: -00px;
                    box-shadow: 10px 10px 16px 0px rgba(4, 23, 50, 0.55);
                    border-bottom: 10px solid var(--second-color);
                }
            }
        }
    }
}

// @media screen and (max-width:899px) {
//     .webHome .encourage .encourageContent{
//         // display: block;
//         justify-content: center;
//     }
// }
@media screen and (max-width:1200px) {
    .webHome {
        .PopularServices {
            .webMain {
                .servicesContent {
                    .item {

                        &:nth-child(2),
                        &:nth-child(6) {
                            border-right: 1px solid #BBBBBB;
                        }

                        &:nth-child(5),
                        &:nth-child(6) {
                            border-bottom: 0px solid #BBBBBB;
                        }
                    }
                }
            }
        }
    }

    .webHome .encourage .encourageContent {
        // display: block;
        justify-content: center;

        .item {
            &:nth-child(3) {
                margin-left: 0px;
            }

            &:nth-child(2) {
                margin-right: 0px;
            }
        }
    }
}

@media screen and (min-width:1200px) {
    .upgradeContent {
        // ::v-deep .el-carousel__container {
        //     height: 458px !important;
        // }

    }
}

@media screen and (max-width:1200px) {
    .webHome .encourage .encourageContent {
        .item {
            margin: 5px 15px !important;
        }
    }

    .upgradeContent {
        .item {
            width: 360px !important;
        }

        //     ::v-deep .el-carousel__container {
        //         height: 530px !important;
        //     }

    }

}

@media screen and (max-width:683px) {
    .webHome {
        .PopularServices {
            .webMain {
                .servicesContent {
                    .item {
                        border: 1px solid #BBBBBB;
                        // &:nth-child(2), &:nth-child(6) {
                        //     border-right: 1px solid #BBBBBB;
                        // }
                        // &:nth-child(5), &:nth-child(6) {
                        //     border-bottom: 0px solid #BBBBBB;
                        // }
                    }
                }
            }
        }
    }

    .webHome .encourage .encourageContent {
        // display: block;
        justify-content: center;

        .item {
            margin: 5px 15px !important;
        }
    }
}
</style>