<template>
  <div :class="$route.path !== '/english/index' ? 'custom footer':'footer'">
    <div class="webMain">
      <div class="top">
        <div class="item" v-for="item in dataList" :key="item.id">
          <p class="title p18">{{ item.name }}</p>
          <ul v-if="item.children">
            <li v-for="i in item.children" :key="i.id">
              <span>{{ i.name }}：</span>
              {{ i.content }}
            </li>
          </ul>
          <img v-else src="../../assets/web/footer/code.png" alt="">
        </div>
      </div>
      <!-- <div class="center">
        <p class="title p18">
          友情链接
        </p>
        <div>
          <span><a href="https://zhihanyixing.com">成都智涵易兴网络信息科技有限公司</a></span>
        </div>
      </div> -->
      <div class="bottom">

        <span>Copyright © 2016-2021 骏领国际商务（深圳）有限公司版权所有</span>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030302002327" style="position: relative;">
          <img style=" position: absolute;left: 25px;" src="../../assets/web/footer/a28c0d2a_12109023.png" alt="">
          <span style="margin-left: 45px;">粤公网安备 44030302002327号</span>
        </a>
        <a style="margin-left: 10px;" href="https://beian.miit.gov.cn/#/Integrated/index">
          粤ICP备2023024070号-1
        </a>
        &nbsp;&nbsp;&nbsp;
        <span>  技术支持： <a href="https://zhihanyixing.com">智涵易兴</a></span>
        
      </div>
    </div>
    <!-- 底部 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        {
          id: 1, name: 'Hong Kong headquarters', children: [
            { id: 1, name: '地址', content: '香港九龍觀塘觀塘道398號Eastcore15樓1507C室' },
            { id: 2, name: 'Address', content: 'Unit 1507C, 15/F., Eastcore, 398 Kwun Tong Road, Kwun Tong, Kowloon, Hong Kong' },
            { id: 3, name: 'phone', content: '00852-97710667/68513686' },
            { id: 4, name: 'fax', content: '00852-23885676' },
          ],
        },
        {
          id: 2, name: 'Shenzhen Branch', children: [
            { id: 1, name: '地址', content: '深圳市罗湖区南湖街道东门南路2020号太阳岛大厦30G' },
            { id: 2, name: 'Address', content: '30G, Taiyangdao Building, No. 2020, Dongmennan Road, Nanhu Subdistrict, Luohu District, Shenzhen' },
            { id: 3, name: 'phone', content: '0755-25857209' },
            { id: 4, name: 'straight line', content: '13926584988' },
            { id: 5, name: 'Manager Lin', content: '15989583077' }

          ],
        },
        {
          id: 3, name: 'Chengdu Branch', children: [
            { id: 1, name: 'Address', content: 'Room 2102, Block C, Jintian International, No. 288, Taisheng South Road, Qingyang District, Chengdu' },
            { id: 2, name: 'phone', content: '028-86759635' },
            { id: 3, name: 'Miss Li', content: '15928115477' }
          ],
        },
        { id: 4, name: 'WeChat', },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 60px;
  color: #222222;
  // background-color: var(--custom-color1);
  a{
      color: var(--second-color);
      text-decoration: none;
    }
  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // justify-content: space-between;


    .item {
      width: 273px;
      padding-bottom: 10px;
      // height: 324px;
      margin-right: 30px;
      &:last-child{
        margin-right: 0px;
      }

      .title {
        font-size: 18px;
        font-weight: 600;
        // color: #222222;
        border-bottom: 1px solid #A0A0A0;
        padding-bottom: 7px;
        margin-bottom: 9px;

      }

      img {
        width: 70%;
      }

      ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
          margin-bottom: 15px;
          font-size: 15px;
          font-weight: 400;
          color: rgba(82, 82, 82, 1);
          line-height: 23px;

          span {
            // color: rgba(34, 34, 34, 1);
            // font-weight: 600;
          }
        }
      }
    }
  }

  .center {
    border-top: 1px solid #222222;
    border-bottom: 1px solid #222222;
    padding-top: 18px;
    padding-bottom: 30px;

    .title {
      font-weight: 600;
      // color: #222222;
      margin-bottom: 10px;
    }

    span,
    a {
      font-size: 16px;
      font-weight: 400;
      color: var(--second-color);
    }
  }

  .bottom {
    padding-top: 18px;
    padding-bottom: 30px;
    font-size: 14px;
    // color: #222222;
   
  }
}
.custom.footer{
  background-color: var(--custom-color1);
  color: white;
  .top{
    .item{
      .title{
        border-bottom: 1px solid white;
      }
      ul {
        li {
          color: white;
         
        }
      }
    }
  }
  .center {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
  }
  .bottom{
    border-top: 1px solid white;
  }
 
}
@media screen and (max-width:1200px) {
  .footer{
    .top{
      .item{
        margin-right: 50px;
        &:nth-child(2){
          margin-right: 0px;
          
        }
      }
    }
  }
}
@media screen and (max-width:683px){
  .footer .top .item{
    margin: 0px 10px !important;
  }
}
</style>