<template>
  <div class="index">
    <Header></Header>

    <router-view></router-view>
    <Footer></Footer>
    <!-- 网站首页 -->
  </div>
</template>

<script>
import Header from '@/components/web/Header.vue';
import Banner from '@/components/web/Banner.vue';
import Footer from '@/components/web/Footer.vue';
export default {
  components: { Header, Banner, Footer },
  created() {
    if (!sessionStorage.getItem('path')) {
      if((this.$route.path).indexOf('detail') !== -1) {
        sessionStorage.setItem('path', '/list/1')
      } else {
        sessionStorage.setItem('path', this.$route.path)
      }
    }

  }
}
</script>

<style>
</style>