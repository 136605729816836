<template>
  <div class="login">
    <img class="background" :src="this.$store.state.requestAPI + settingsInfo.background" alt="">
    <!-- 登录页面 -->
    <div class="loginContent">
      <div class="left">
        <img :src="this.$store.state.requestAPI + settingsInfo.login" alt="">
      </div>
      <div class="right">
        <div class="formContent">
          <p class="name">{{ settingsInfo.name }}</p>
          <p class="title">-{{ settingsInfo.title }}</p>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
            <el-form-item prop="username">
              <el-input prefix-icon="el-icon-user" v-model="ruleForm.username" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input prefix-icon="el-icon-lock" type="password" v-model="ruleForm.password"
                placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <div class="code">
                <el-input prefix-icon="el-icon-goods" v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
                <div @click="changeCode()">
                  <!-- 引入验证码组件 -->
                  <identify :identifyCode="identifyCode"></identify>
                </div>
              </div>

            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import identify from '@/components/identify.vue'
import { loginAPI } from '@/api/user/user'
import { basicSettingsAPI } from '@/api/settings/basic'
export default {
  components: { identify },
  data() {
    return {
      identifyCode: '1234', // 默认验证码
      // 验证码的随机取值范围
      identifyCodes: '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYz',
      ruleForm: {
        username: null,
        password: null,
        code: null

      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],

        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ]
      },

      settingsInfo: {}//设置详情
    }
  },
  methods: {
    // 点击登录
    submitForm(formName) {
      console.log(this.identifyCode)
      console.log(this.ruleForm)
      // console.log((this.ruleForm.code).toUpperCase())
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.code) {
            // 先验证 验证码是否填写正确
            if ((this.ruleForm.code).toUpperCase() !== this.identifyCode) { // 验证码填写错误
              this.$message({
                showClose: true,
                type: 'error',
                message: '验证码不正确，请重新输入！'
              });
            } else { // 验证码填写正确,调取接口判断用户名和密码
              let info = this.ruleForm;
              delete info.code;
              const res = await loginAPI(JSON.stringify(this.ruleForm));
              console.log(res);
              if (res.code === 200) {
                if (res.data.user.menus.length > 0) {
                  //登录成功进入首页
                  sessionStorage.setItem('token', res.data.token);
                  sessionStorage.setItem('user', JSON.stringify(res.data.user));
                  sessionStorage.setItem('menus', JSON.stringify(res.data.user.menus))
                  this.$message({
                    showClose: true,
                    type: 'success',
                    message: '登录成功！'
                  });
                  setTimeout(() => {
                    let menus = res.data.user.menus;
                    this.$router.push(menus[0].children[0].url)
                  }, 2000);
                } else {
                  this.$message({
                    showClose: true,
                    type: 'error',
                    message: '登录失败！当前用户无权限，请联系管理员。'
                  });
                }

              } else {
                this.$message({
                  showClose: true,
                  type: 'error',
                  message: '登录失败！' + res.msg
                });
              }
            }
          } else {
            this.$message({
                showClose: true,
                type: 'error',
                message: '请刷新验证码重新输入！'
              });
          }

          // alert('submit!');
        } else {
          console.log('请将信息填写完整');

          return false;
        }
      });
    },
    // 获取设置详情
    async getSettingsDetail() {
      const res = await basicSettingsAPI();
      console.log(res);
      if (res.code === 200) {
        this.settingsInfo = res.data;
      }
    },
    // 点击验证码刷新验证码
    changeCode() {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    }
  },

  mounted() {
    // let data = this.$md5(this.passWord)
    // console.log(data)// e10adc3949ba59abbe56e057f20f883e
    // 刷新页面就生成随机验证码
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },
  created() {
    this.getSettingsDetail();
  }

}
</script>

<style lang="scss" scoped>
.login {
  // background: url(settingsInfo);
  // background-color: var(--custom-color);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .background{
    position: absolute;
   
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .loginContent {
    z-index: 1;
    display: flex;
    background: var(--white-color);
    height: 60%;
    width: 50%;
    border-radius: 10px;
    box-shadow: 10px 10px 14px 0px rgba(0,0,0,0.2);
    // box-shadow: ;

    .left {
      // border-radius: 10px;
      width: 30%;
      height: 100%;
      // border-radius: 10px;

      img {
        // border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        height: 100%;
      }

      // background: red;
    }

    .right {
      width: calc(100% - 30%);
      text-align: center;
      display: flex;
      // align-items: center;
      justify-content: center;
      padding-top: 30px;

      .formContent {
        width: 60%;

        .name {
          font-size: 28px;
          // font-weight: 600;
        }

        .title {
          text-align: right;
          font-size: 20px;
          margin-bottom: 20px;
        }

        .code {
          display: flex;
          align-items: center;
        }

        ::v-deep .el-form-item__content {
          line-height: 0px !important;
        }

        p,
        .el-form {
          width: 100%;
        }

        // ::v-deep .el-form-item__content{
        //     display: flex !important; 
        //   }
        .el-form {

          .el-button {
            width: 100%;
          }
        }
      }

    }
  }
}
</style>