<template>
    <!-- 关于我们 -->
    <div class="contact">
        <div class="banner">
            <div class="promptText">
                <div class="webMain">
                    <p class="Title">{{ $store.state.imgPath.text.title }}</p>
                    <span class="border"></span>
                    <p class="Text">{{ $store.state.imgPath.text.content }}</p>
                </div>
            </div>
            <img :src="$store.state.requestAPI + $store.state.imgPath.list" alt="">
        </div>
        <!-- —  骏领简介  — -->
        <div class="introduction">
            <div class="webMain">
                <p class="title p30">
                    — Introduction —
                </p>
                <p class="content p16">
                    Junling International is a company focused on enterprise services, providing comprehensive accounting and tax reporting, qualification processing, trademark registration, internet and data construction services. We have an experienced and highly professional team with first-class professional knowledge in finance, law, administration, and other fields, as well as rich practical experience. We will tailor solutions tailored to the actual needs of enterprise customers, allowing them to experience professional, reasonable, worry free, and cost-effective services. We adhere to the concept of "customer first, service first", with the goal of winning customer trust and satisfaction, and wholeheartedly provide customers with high-quality enterprise services.
                </p>
                <div class="imgContent">
                    <div class="left">
                        <el-carousel :interval="5000" arrow="never">
                            <el-carousel-item v-for="item in imgList.Rotation" :key="item.id">
                                <img :src="$store.state.requestAPI + item.path" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="right">
                        <div class="top">
                            <img :src="$store.state.requestAPI + imgList.left"
                                alt="">
                            <img :src="$store.state.requestAPI + imgList.right"
                                alt="">
                        </div>
                        <div class="bottom">
                            <img :src="$store.state.requestAPI + imgList.bottom" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- —  骏领文化理念  — -->
        <div class="culturalConcepts">
            <p class="title p30">
                — Cultural concepts —
            </p>
            <div class="culturalContent webMain">
                <div class="item" v-for="item in culturalList" :key="item.id">
                    <img class="basic" :src="require(`../../assets/web/contact/${item.imgPath}`)" alt="">
                    <img class="hover" :src="require(`../../assets/web/contact/${item.hoverImgPath}`)" alt="">
                    <p class="">
                        {{ item.title }}
                    </p>
                </div>
            </div>
        </div>
        <!-- 骏领的合作伙伴 -->
        <div class="cooperativePartner">
            <div class="webMain">
                <p class="title p30">— Partners —</p>
                <div class="cooperativePartnerContent">
                    <div class="item" v-for="item in cooperativePartnerList" :key="item.id">
                        <img class="basic" :src="require(`../../assets/web/contact/${item.imgPath}`)" alt="">
                        <img class="hover" :src="require(`../../assets/web/contact/${item.hoverImgPath}`)" alt="">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { attachmentListAPI } from '@/api/attachment/attachment'
export default {
    data() {
        return {
            culturalList: [
                { id: 1, title: 'solidarity', imgPath: 'culture (1).png', hoverImgPath: 'culture2 (1).png' },
                { id: 2, title: 'innovate', imgPath: 'culture (2).png', hoverImgPath: 'culture2 (2).png' },
                { id: 3, title: 'fair', imgPath: 'culture (3).png', hoverImgPath: 'culture2 (3).png' },
                { id: 4, title: 'responsibility', imgPath: 'culture (4).png', hoverImgPath: 'culture2 (4).png' },
                { id: 5, title: 'Multi-win', imgPath: 'culture (5).png', hoverImgPath: 'culture2 (5).png' },
                { id: 6, title: 'study', imgPath: 'culture (6).png', hoverImgPath: 'culture2 (6).png' },
            ],
            cooperativePartnerList: [
                { id: 1, imgPath: 'partner (1).png', hoverImgPath: 'partner2 (1).png' },
                { id: 2, imgPath: 'partner (2).png', hoverImgPath: 'partner2 (2).png' },
                { id: 3, imgPath: 'partner (3).png', hoverImgPath: 'partner2 (3).png' },
                { id: 4, imgPath: 'partner (4).png', hoverImgPath: 'partner2 (4).png' },
                { id: 5, imgPath: 'partner (5).png', hoverImgPath: 'partner2 (5).png' },
                { id: 6, imgPath: 'partner (6).png', hoverImgPath: 'partner2 (6).png' },
                { id: 7, imgPath: 'partner (7).png', hoverImgPath: 'partner2 (7).png' },
                { id: 8, imgPath: 'partner (8).png', hoverImgPath: 'partner2 (8).png' },
                { id: 9, imgPath: 'partner (9).png', hoverImgPath: 'partner2 (9).png' },
                { id: 10, imgPath: 'partner (10).png', hoverImgPath: 'partner2 (10).png' },
                { id: 11, imgPath: 'partner (11).png', hoverImgPath: 'partner2 (11).png' },
                { id: 12, imgPath: 'partner (12).png', hoverImgPath: 'partner2 (12).png' },
            ],
            carouselList: [
                { id: 1, path: '/image/99a662b1f2044df083404070a53a409799a662b1f2044df083404070a53a4097.png' },
                { id: 2, path: '/image/99a662b1f2044df083404070a53a409799a662b1f2044df083404070a53a4097.png' },
                { id: 3, path: '/image/99a662b1f2044df083404070a53a409799a662b1f2044df083404070a53a4097.png' },
            ],
            imgList: {
                bottom: '',
                Rotation: [],
                left: '',
                right: ''
            }

        }
    },
    methods: {
        // 获取附件列表
        async getAttachmentList() {
            // console.log(val)
            let info = { pageSize: 100000, pageNum: 1, categoryId: 20 }
            const res = await attachmentListAPI(info);
            console.log(res);

            if (res.code === 200) {
                res.data.list.forEach(item => {
                    if (item.title === 'bottom.png') {
                        this.imgList.bottom = item.path
                    } else if (item.title === 'left.png') {
                        this.imgList.left = item.path;
                    } else if (item.title === 'right.png') {
                        this.imgList.right = item.path;
                    } else {
                        this.imgList.Rotation.push(item)
                    }
                })
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }

        },
    },
    created() {
        this.getAttachmentList();
    }
}
</script>

<style lang="scss" scoped>
.contact {
    .banner {
        img {
            height: 420px;
            width: 100%;
        }
    }

    @keyframes shadow {
        from {
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.22);

        }

        to {
            background-color: var(--second-color);
            box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.49);
            color: white;
        }
    }

    @keyframes style {
        from {
            background: white;
            border: 1px solid #979797;
        }

        to {

            border: 1px solid var(--second-color);
            background: #F4F8FF;

        }
    }


    .introduction {
        padding: 60px 0px;

        .webMain {
            .content {
                color: #525252;
                line-height: 25px;
            }

            .imgContent {
                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                img {
                    &:hover {
                        cursor: pointer;
                        animation-name: shadow;
                        animation-duration: 0.5s;
                        animation-fill-mode: both;
                    }
                }

                // .left, .right{
                //     width: 50%;
                //     box-sizing: border-box;
                // }
                .left {
                    width: 512px;
                    margin-right: 36px;
                    margin-bottom: 30px;

                    img {
                        width: 100%;
                        height: 100%;


                    }

                    // padding-right: 30px;
                    ::v-deep .el-carousel__container {
                        height: 510px !important;
                    }
                }

                .right {
                    width: 626px;
                    // margin-top: 30px;

                    .top {
                        margin-bottom: 30px;
                        height: 294px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        img {
                            width: 294px;
                            height: 100%;

                            // &:first-child {
                            //     margin-right: 36px;
                            // }
                        }
                    }

                    .bottom {
                        img {
                            width: 100%;
                            height: 190px;
                        }
                    }
                }
            }
        }
    }

    .culturalConcepts {
        padding: 60px 0px;
        padding-top: 60px;
        background-size: cover;
        // padding-bottom: 60px;
        background-image: url('../../assets/web/contact/d9d8469c343039f398c3520b848426b7b75501c3254ff-y1zUup_fw1200-2@2x.png');

        .culturalContent {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            // background-color: white;
            .item {
                width: 398px;
                height: 225px;
                border: 1px solid #BBBBBB;
                text-align: center;
                background-color: white;
                padding-top: 10px;
                box-sizing: border-box;
                color: #525252;

                &:hover {

                    animation-name: shadow;
                    animation-duration: 0.5s;
                    animation-fill-mode: both;


                    .basic {
                        display: none;
                        // animation-name: showImg2;
                        // animation-duration: 0.5s;
                        // animation-fill-mode: both;
                    }

                    .hover {
                        display: inline-block;
                        // animation-name: showImg;
                        // animation-duration: 0.5s;
                        // animation-fill-mode: both;
                    }


                }

                img {
                    height: 130px;
                    width: 130px;

                }

                .hover {
                    display: none;
                }

                p {
                    font-size: 34px;
                    font-weight: 600;

                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    border-bottom: 0px;
                }

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(5) {
                    border-right: 0px;
                }
            }
        }
    }

    .cooperativePartner {
        padding: 60px 0px;

        .cooperativePartnerContent {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .item {
                width: 180px;
                height: 150px;
                background: #FFFFFF;
                border: 1px solid #979797;
                margin: 10px 8px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {

                    animation-name: style;
                    animation-duration: 0.5s;
                    animation-fill-mode: both;

                    // .basic {
                    //     display: none;
                    // }

                    // .hover {
                    //     display: block;
                    // }

                }

                img {
                    max-width: 158px;
                    min-width: 56px;
                    max-height: 64px;
                    min-height: 21px;
                }

                .basic {
                    display: none;
                }

                // &:nth-child(6),&:nth-child(12) {
                //     margin-right: 0px;
                // }
            }
        }
    }

    .title {
        // margin-top: 60px;
        margin-bottom: 30px;
        text-align: center;
        font-weight: 600;
        color: #222222;
    }
}

@keyframes showImg {
    from {
        // visibility: hidden;
    }

    to {
        // display: block;
        // visibility: visible;

    }
}

@keyframes showImg2 {
    // from {
    //     // display: block;
    //     // background: #222222;

    // }

    // to {
    //     visibility: hidden;
    //     // background: #222222;

    // }
}

@media screen and (max-width:1200px) {
    .contact {
        .culturalConcepts {
            .culturalContent {
                .item {
                    &:nth-child(4) {
                        border-left: 0px;
                        border-bottom: 0px;
                    }
                }
            }
        }

        .introduction {
            .webMain {
                .imgContent {
                    .left {
                        width: 100%;
                        margin-right: 0px;
                    }

                    .right {
                        width: 100%;

                        .top {
                            img {
                                width: 47%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:900px) {
    .contact {
        .culturalConcepts {
            .culturalContent {
                .item {
                    width: 370px;

                    &:nth-child(3) {
                        border: 1px solid #BBBBBB;
                    }

                    // &:nth-child(4){
                    //     border-left: 1px solid #BBBBBB;
                    //     // border-bottom: 0px;
                    // }
                }
            }
        }
    }
}
</style>