<template>
    <div class="articleType">
        <!-- 文章分类 -->
        <div class="left">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="名称" prop="title">
                    <el-input v-model="ruleForm.title" size="small"></el-input>
                </el-form-item>
                <el-form-item label="父级" prop="pid">
                    <el-cascader :options="options" v-model="ruleForm.pid"
                        :props="{ label: 'title', value: 'id', checkStrictly: true, emitPath: false }"
                        :show-all-levels="false" change-on-select size="small" style="width:100%"
                        @change="getCheckedNodes($event)"></el-cascader>
                </el-form-item>
                <el-form-item label="固定链接" prop="slug">
                    <el-input v-model="ruleForm.slug" size="small"></el-input>
                    <span>此文章分类的 URL 地址为：
                        {{ settingsInfo.siteAddress }}/固定连接</span>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="ruleForm.orderNumber" size="small" type="number"></el-input>
                    <span>越小的数字越靠前，只在同级分类上影响。</span>
                </el-form-item>
                <el-form-item label="内容">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入内容"
                        v-model="ruleForm.content">
                    </el-input>
                </el-form-item>
                <el-form-item label="摘要">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入内容"
                        v-model="ruleForm.summary">
                    </el-input>
                </el-form-item>
                <el-form-item label="图标">
                    <el-input v-model="ruleForm.icon" size="small"></el-input>
                    <span>图标只用于前台显示，是否显示决定于模板。</span>
                </el-form-item>
                <el-form-item label="标识">
                    <el-input v-model="ruleForm.flag" size="small"></el-input>
                </el-form-item>
                <el-form-item label="是否推荐">
                    <el-switch v-model="ruleForm.withRecommend">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否置顶">
                    <el-switch v-model="ruleForm.withTop">
                    </el-switch>
                </el-form-item>
                <!-- <el-form-item label="显示到菜单">
                    <el-switch v-model="ruleForm.withMenu">
                    </el-switch>
                </el-form-item> -->
                <el-form-item>
                    <el-collapse>
                        <el-collapse-item title="SEO">
                            <div class="item">
                                <span>标题</span>
                                <el-input v-model="ruleForm.metaTitle" size="small"></el-input>
                            </div>
                            <div class="item">
                                <span>描述</span>
                                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入内容"
                                    v-model="ruleForm.metaDescription">
                                </el-input>
                            </div>
                            <div class="item">
                                <span>关键字</span>
                                <el-input v-model="ruleForm.metaKeywords" size="small"></el-input>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="right">
            <template>
                <el-table :data="tableData" stripe style="width: 100%" row-key="id" border default-expand-all
                    :tree-props="{ children: 'children', hasChildren: 'childCategory' }">
                    <el-table-column prop="id" label="id">
                    </el-table-column>
                    <el-table-column label="标题">
                        <template slot-scope="scope">
                            <span>{{ scope.row.title }} ({{ scope.row.count }}) </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="slug" label="固定链接">
                    </el-table-column>
                    <el-table-column prop="content" label="描述">
                    </el-table-column>
                    <el-table-column prop="orderNumber" label="排序">
                    </el-table-column>
                    <el-table-column label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="getDetail(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="deleteType(scope.row.id)">删除</el-button>
                            <el-button type="text" size="small">访问</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <!-- <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total,  prev, pager, next, jumper" :total="paging.total">
            </el-pagination> -->
        </div>
    </div>
</template>

<script>
// import { articleTypeListAPI } from '@/api/article/article'
import { articleTypeListAPI, addArticleTypeAPI, articleTypeDetailAPI, updateArticleTypeAPI, deleteArticleTypeAPI } from '@/api/article/type'
import { basicSettingsAPI } from '@/api/settings/basic'
export default {
    data() {
        return {
            isEdit: false,
            settingsInfo: {}, // 设置的基础信息
            // 表单信息
            ruleForm: {
                title: null,
                pid: 0,
                slug: null,
                orderNumber: null,
                content: null,
                summary: null,
                icon: null,
                flag: null,
                withRecommend: false,
                withTop: false,
                // withMenu: false

            },
            // 分页
            paging: {
                p: 1,
                ps: 20,
                total: 0,
            },
            rules: {
                title: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                pid: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                slug: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            options: [],

            tableData: []
        }
    },
    methods: {
        // 获取用户选中的节点
        getCheckedNodes(val) {
            // console.log(val)
            if (val.length > 0) {
                if (val.length > 1) {
                    this.ruleForm.pid = val[val.length - 1]
                } else {
                    this.ruleForm.pid = val[0]
                }
            }

        },
        // 获取设置详情
        async getSettingsDetail() {
            const res = await basicSettingsAPI();
            console.log(res);
            if (res.code === 200) {
                this.settingsInfo = res.data;
            }
        },
        // 编辑/新增分类
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // 判断是新增还是编辑
                    if (this.isEdit === true) { // 编辑
                        console.log('编辑')
                        const res = await updateArticleTypeAPI(JSON.stringify(this.ruleForm));
                        console.log(res);
                        if (res.code === 200) {
                            this.$message({
                                showClose: true,
                                message: '操作成功！',
                                type: 'success'
                            });

                            this.getList();
                        } else {
                            this.$message({
                                showClose: true,
                                message: '操作失败！' + res.msg,
                                type: 'error'
                            });
                        }
                    } else {
                        const res = await addArticleTypeAPI(JSON.stringify(this.ruleForm));
                        console.log(res);
                        if (res.code === 200) {
                            this.$message({
                                showClose: true,
                                message: '操作成功！',
                                type: 'success'
                            });
                            this.getList();
                        } else {
                            this.$message({
                                showClose: true,
                                message: '操作失败！' + res.msg,
                                type: 'error'
                            });
                        }
                    }
                    this.ruleForm = {
                        title: null,
                        pid: 0,
                        slug: null,
                        orderNumber: null,
                        content: null,
                        summary: null,
                        icon: null,
                        flag: null,
                        withRecommend: false,
                        withTop: false,
                        // withMenu: false

                    }
                    this.isEdit = false;

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 删除文章分类
        async deleteType(val) {

            this.$confirm('确认删除该分类?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let arr = [val]
                const res = await deleteArticleTypeAPI(JSON.stringify(arr));
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        showClose: true,
                        message: '操作成功!'
                    });
                    this.getList();
                } else {
                    this.$message({
                        showClose: true,
                        message: '操作失败！' + res.msg,
                        type: 'error'
                    });
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        // 获取文章分类列表
        async getList() {
            let info = { pageNum: this.paging.p, pageSize: this.paging.ps };
            const res = await articleTypeListAPI(info);
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.list;
                this.options = [{ id: 0, title: '顶级' }, ...JSON.parse(JSON.stringify(res.data.list))];

            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
        },
        // 获取文章分类详情
        async getDetail(val) {
            this.isEdit = true;
            const res = await articleTypeDetailAPI({ id: val });
            console.log(res);
            if (res.code === 200) {
                this.ruleForm = res.data;
                // this.checkOption = res.data.pid;
                // console.log(this.checkOption);
                // console.log(this.options)
                // this.getList();
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }
        },
        // 分页
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        }
    },
    created() {
        this.getList();
        this.getSettingsDetail();
    }
}
</script>

<style lang="scss" scoped>
.articleType {
    display: flex;
    height: 100%;
    justify-content: space-between;

    .left {
        width: 40%;
        padding-right: 40px;
    }

    .right {
        width: 59%;
        // height: 100%;
        min-height: 500px;
        // background: wheat;
    }
}
</style>