<template>
    <div class="articleType">
        <!-- 文章标识 -->
        <div class="left">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="名称" prop="title">
                    <el-input v-model="ruleForm.title" size="small"></el-input>
                </el-form-item>

                <el-form-item label="内容">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入内容"
                        v-model="ruleForm.content">
                    </el-input>
                </el-form-item>
                <el-form-item label="摘要">
                    <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入内容"
                        v-model="ruleForm.summary">
                    </el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="ruleForm.orderNumber" size="small" type="number"></el-input>
                    <span>越小的数字越靠前，只在同级分类上影响。</span>
                </el-form-item>
                <!-- <el-form-item label="显示到菜单">
                    <el-switch v-model="ruleForm.withRecommend">
                    </el-switch>
                </el-form-item> -->
                <el-form-item>
                    <el-collapse>
                        <el-collapse-item title="SEO">
                            <div class="item">
                                <span>标题</span>
                                <el-input v-model="ruleForm.metaTitle" size="small"></el-input>
                            </div>
                            <div class="item">
                                <span>描述</span>
                                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入内容"
                                    v-model="ruleForm.metaDescription">
                                </el-input>
                            </div>
                            <div class="item">
                                <span>关键字</span>
                                <el-input v-model="ruleForm.metaKeywords" size="small"></el-input>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="right">
            <template>
                <el-table :data="tableData" stripe style="width: 100%">
                    <el-table-column prop="id" label="ID">
                    </el-table-column>
                    <el-table-column prop="title" label="标题">
                    </el-table-column>
                    <el-table-column prop="content" label="描述">
                    </el-table-column>
                    <el-table-column prop="orderNumber" label="排序">
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="tagsDetail(scope.row.id)">编辑</el-button>
                            <el-button type="text" size="small" @click="deleteTags(scope.row.id)">删除</el-button>
                            <el-button type="text" size="small">访问</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
                layout="total,  prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { articleTagsListAPI, addArticleTagsAPI, detailArticleTagsAPI, updateArticleTagsAPI, deleteArticleTagsAPI } from '@/api/article/TCCTags'
export default {
    data() {
        return {
            isEdit: false,
            // 表单信息
            ruleForm: {
                title: null,
                content: null,
                summary: null,
                orderNumber: null,
                metaTitle: null,
                metaDescription: null,
                metaKeywords: null,
                withRecommend: false
            },
            // 分页
            paging: {
                p: 1,
                ps: 20,
                total: 0,
            },
            rules: {
                title: [
                    { required: true, message: '请输入必填内容', trigger: 'blur' },
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            options: [],
            tableData: []
        }
    },
    methods: {
        // 新增/ 修改
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    // alert('submit!');
                    let res = {};
                    console.log(this.ruleForm);
                    if (this.isEdit === true) { // 修改
                        res = await updateArticleTagsAPI(JSON.stringify(this.ruleForm));
                        console.log(res);
                    } else {

                        res = await addArticleTagsAPI(JSON.stringify(this.ruleForm));
                        console.log(res);
                    }

                    if (res.code === 200) {
                        this.$message({
                            showClose: true,
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.ruleForm = {
                            title: null,
                            content: null,
                            summary: null,
                            orderNumber: null,
                            metaTitle: null,
                            metaDescription: null,
                            metaKeywords: null,
                            withRecommend: false
                        }
                        this.isEdit = false;
                        this.getList();
                    } else {
                        this.$message({
                            showClose: true,
                            message: '操作失败！' + res.msg,
                            type: 'error'
                        });
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 切换分页
        handleCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.paging.p = val;
            this.getList();
        },
        // 获取文章标签列表
        async getList() {
            const res = await articleTagsListAPI({ pageNum: this.paging.p, pageSize: this.paging.ps });
            console.log(res);
            if (res.code === 200) {
                this.tableData = res.data.list;
                this.paging.total = res.data.total;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '列表获取失败!'
                });
            }
        },
        // 获取标签详情
        async tagsDetail(val) {
            // console.log();
            this.isEdit = true;
            const res = await detailArticleTagsAPI({ id: val });
            console.log(res);
            if (res.code === 200) {
                this.ruleForm = res.data;
            } else {
                this.$message({
                    type: 'info',
                    showClose: true,
                    message: '详情获取失败!'
                });
            }
        },
        // 删除标签
        async deleteTags(val) {
            // let arr = [val]
            // const res = await deleteArticleTagsAPI(JSON.stringify(arr));
            // console.log(res);
            // if(res.code === 200) {

            // }
            this.$confirm('确认删除该分类?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let arr = [val]
                const res = await deleteArticleTagsAPI(JSON.stringify(arr));
                console.log(res);
                if (res.code === 200) {
                    this.$message({
                        type: 'success',
                        showClose: true,
                        message: '删除成功!'
                    });
                    this.getList();
                } else {
                    this.$message({
                        showClose: true,
                        message: '操作失败！' + res.msg,
                        type: 'error'
                    });
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }


    },
    created() {
        this.getList();
    },
}
</script>

<style lang="scss" scoped>
.articleType {
    display: flex;
    height: 100%;
    justify-content: space-between;

    .left {
        width: 40%;
        padding-right: 40px;
    }

    .right {
        width: 59%;
        // height: 100%;
        min-height: 500px;
        // background: wheat;
    }
}
</style>