import request from '@/utils/request'

import store from '@/store'
store.dispatch('getAPI');

// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// 获取用户列表
export function getUsersListAPI(data) {
    return request({
        url: `/user/findList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 新增用户
export function addUserAPI(data) {
  return request({
      url: `/user/addUser?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}
// 修改用户
export function updateUserAPI(data) {
  return request({
      url: `/user/updateUser?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}
// 修改密码
export function updateUserPassAPI(data) {
  return request({
      url: `/user/uodatePassword?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}

// 删除用户
export function deleteUserAPI(data) {
  return request({
      url: `/user/deleteUser?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}

// 获取用户详情
export function userDetailAPI(params) {
    let newParam = {...params, ...info};
    return request({
      url: '/user/findById',
      method: 'get',
      params: newParam
    });
}

// 用户登录
export function loginAPI(data) {
  return request({
      url: `/admin/login?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}

// 获取菜单列表
export function menuListAPI(params) {
  let newParam = {...params, ...info};
  return request({
    url: '/menu/menuList',
    method: 'get',
    params: newParam
  });
}

// 设置菜单列表
export function setMenuAPI(params) {
  let newParam = {...params, ...info};
  return request({
    url: '/menu/menuList',
    method: 'get',
    params: newParam
  });
}

//设置用户权限
export function setUserMenuAPI(data) {
  return request({
      url: `/user/updateAuthorize?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
      method: 'post',
      data
    });
}