import request from '@/utils/request'

import store from '@/store'
store.dispatch('getAPI');

// var info = store.state.APIinfo;
var info = JSON.parse(sessionStorage.getItem('APIinfo'));
// 获取文章标签列表
export function articleTagsListAPI(params) {
    let newParam = {...params, ...info};
    return request({
      url: '/EnglishArticle/tagsList',
      method: 'get',
      params: newParam
    });
}
// 新增文章标签
export function addArticleTagsAPI(data) {
    return request({
        url: `/EnglishArticle/addTags?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 修改文章标签
export function updateArticleTagsAPI(data) {
    return request({
        url: `/EnglishArticle/updateTag?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 删除文章标签
export function deleteArticleTagsAPI(data) {
    return request({
        url: `/EnglishArticle/deleteTagList?ct=${info.ct}&jpressAppId=${info.jpressAppId}&sign=${info.sign}`,
        method: 'post',
        data
      });
}
// 文章标签详情
export function detailArticleTagsAPI(params) {
    let newParam = {...params, ...info};
    return request({
      url: '/EnglishArticle/findTagById',
      method: 'get',
      params: newParam
    });
}